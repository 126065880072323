<template>
  <div class="home-recruting wrap">
    <div class="flex-cover">
      <div class="left">
        <h2>Recruiting<br />information</h2>
        <p class="sub-title">求人・採用</p>
        <h3>
          勤務時間が自由。<br /><span class="left-margin"
            >「時間的な自由を求める人」に</span
          ><br />ピッタリな職場
        </h3>
        <p class="text">
          仕事において「時間的な自由を求める人」は、ぜひ株式会社グレイトヘルプの求人をチェックしてみてください。家事や子育てに忙しい主婦・主夫、フリーランス・個人事業主としてご自身のお仕事をしながら勤務される方が主となって活躍する職場です。勤務曜日が自由、勤務時間が自由、無駄な拘束時間なしなど、仕事において時間的な制約がある人が働きやすい勤務条件と職場環境を整えています。
        </p>
      </div>
      <div class="right">
        <img alt="great help policy" src="@/assets/GH-corporate-003.png" />
      </div>
    </div>
    <div class="blue-bar"><p>現在募集中のお仕事</p></div>
    <div class="flex">
      <p class="pa">パート・アルバイト</p>
      <p class="text">プログラマー、ウェブサイト制作、ウェブデザイナー、デザイナー、マーケティングサポート、広告運用サポート、経理、事務</p>
    </div>
    <a
      href="https://recruit.greathelp.co.jp/"
      target="_blank"
      rel="noopener noreferrer"
      class="link-button"
      ><p>さらに詳しい情報を見る</p></a
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiTable from "@/components/Ui/Table.vue";
// import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "home-recruting",
  components: {
    // UiTable,
    // UiSliderBox,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.flex-cover {
  align-items: flex-end;
  @media screen and (max-width: 767px){
    align-items: center;
  }
  .left {
    width: 50%;
    @media screen and (max-width: 767px){
      width: 100%;
    }
    h3 {
      margin-top: 70px;
    }
    .left-margin {
      margin-left: -1rem;
    }
  }
  .right {
    width: 45%;
    @media screen and (max-width: 767px){
      width: 90%;
      margin-top: 20px;
    }
  }
}
.blue-bar{
  width: 100%;
  background-color: var(--main);
  padding: 10px 20px;
  margin-top: 100px;
  margin-bottom: 30px;
  p{
    color: white;
  }
}
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  @media screen and (max-width: 767px){
    flex-direction: column;
  }
.pa{
  border: 1px solid var(--main);
  border-radius: 50px; 
  width: 20%;
  text-align: center;
  padding: 10px 0;
  color: var(--main);
  @media screen and (max-width: 767px){
    width: 100%;
  }
}
.text{
width: 75%;
line-height: 1.5;
@media screen and (max-width: 767px){
  width: 100%;
  margin-top: 20px;
}
}  
}
.link-button{
  width: 250px;
  margin-top: 50px;
  padding: 10px 0;
}
</style>
