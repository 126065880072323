<template>
  <div class="home-contact wrap">
    <h2 id="mailto">Contact</h2>
    <p class="sub-title">お仕事のご依頼・<br class="sp-only">求人に関するお問い合わせ</p>

      <HomeContactContactForm
        class="contact-form"
      />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";
import HomeContactContactForm from "@/components/Home/Contact/ContactForm.vue";

export default {
  name: "HomeAboutUs",
  components: {
    // UiHeading,
    HomeContactContactForm,
  },
  props: {},
  data() {
    return {
      pageType: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.contact-form{
  margin-top: 50px;
}
</style>