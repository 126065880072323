<template>
  <div class="home-about-us">
    <div class="flex-cover wrap">
      <div class="left">
        <h2>Greeting from<br />a Representative<br />at Great Help</h2>
        <p class="sub-title">代表者あいさつ</p>
        <p class="text">
          株式会社グレイトヘルプのホームページをご覧いただき、ありがとうございます。当社はウェブのマーケティングと媒体制作を強みに、事業主様の支援を行っています。ウェブ・ITは、年々と高度化し、また、事業への影響範囲が大きくなってきています。そのため、ウェブ・ITの技術の有無によって事業のパフォーマンスに大きな影響が出る半面、多くの人にとって「わかりづらい」「難しい」と感じるのがウェブ・ITだという問題があります。ウェブ・ITを高い技術で全面的にサポートすることでクライアントは自身の事業に注力できる、そんな価値を提供していきます。
        </p>
      </div>
      <div class="right">
        <img
          alt="小関貴久"
          src="@/assets/GH-kyujin-top-ozeki.jpg"
        />
        <p class="name">株式会社グレイトヘルプ</p>
        <p class="name"><span class="daihyo">代表取締役</span>小関 貴久</p>
        <p class="text">
          1983年生まれ。岐阜県出身。大学卒業後、システム開発企業や自動車部品メーカーで勤務。28歳のときに会社を辞め、自宅でパソコン1台でできるアフィリエイトで起業。独自のマーケティングノウハウで数々の商品において販売数日本一を獲得。現在は、マーケティングとウェブ媒体の制作を基盤にし、ウェブに特化した事業を運営。趣味は読書。美容健康オタク。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiTable from "@/components/Ui/Table.vue";
// import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "home-top-class-website",
  components: {
    // UiTable,
    // UiSliderBox,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.flex-cover {
  .left {
    width: 50%;
    @media screen and (max-width: 767px){
      width: 100%;
    }
    .text{
      margin-top: 70px;
    }
  }
  .right {
    width: 45%;
    padding: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 767px){
      width: 100%;
      margin-top: 30px;
      padding: 20px;
    }
    img{
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    .name{
      font-size: 22px;
      .daihyo{
        font-size: 16px;
        margin-right: 10px;
      }
    }
    .text{
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
.slider {
  margin-top: 100px;
}
</style>
