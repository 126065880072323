import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '株式会社グレイトヘルプ',
      description: '株式会社グレイトヘルプは、ウェブを主としたマーケティング・媒体制作による「売上アップ」と、業務のシステム化による「コスト削減」を強みにしたIT企業です。コンサルテイション、ホームページやネットショプの制作、インターネット広告やSNSの運用、SaaS（システムの販売）などを通して、事業主様の業績向上を支援しています。'
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");
  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
  });

export default router
