<template>
  <div class="home">

    <div class="top">
      <HomeTop />
    </div>

<div class="back-video">
  <video loop autoplay muted playsinline>
          <source src="@/assets/back.mp4" />
          <!-- <source src="@/assets/header1280-720px.mp4" /> -->
          このブラウザではサポートされていません。
        </video>

  <div class="top pt-200 pb-200 back-01">
    <HomeAboutUs />
  </div>

  <div class="top pt-200 pb-200">
    <HomeCompanyInfo />
  </div>

  <div class="top pt-200 pb-200 back-01">
    <HomeGreeting />
  </div>

  <div class="top pt-200 pb-200">
    <HomeRecruiting />
  </div>

  <div class="top pt-200 pb-200 back-01">
    <HomeContact />
  </div>
  
</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeTop from "@/components/Home/Top.vue";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import HomeCompanyInfo from "@/components/Home/CompanyInfo.vue";
import HomeGreeting from "@/components/Home/Greeting.vue";
import HomeRecruiting from "@/components/Home/Recruiting.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    HomeTop,
    HomeAboutUs,
    HomeCompanyInfo,
    HomeGreeting,
    HomeRecruiting,
    HomeContact,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.back-video{
  video {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0.4;
}
}
.back-01{
  background-color: rgba(23, 28, 97,0.1);
}
.background-color2 {
  background-color: var(--sub);
}
</style>
